import React from 'react';
import styled from 'styled-components';
import { type ItemTrackerComponent } from 'tracking/ItemTracker';

import { colors } from '@news/design-tokens/src/colors';
import type { ArticleGroupFieldsFragment } from '@news/gql';
import { ImagePlacement, Layout } from '@news/gql';

import { ArticleGroupBlockArticleLink } from './ArticleGroupBlockArticleLink';
import { getMobileLayout } from './getMobileLayout';

interface ArticleGroupBlockProps {
  block: ArticleGroupFieldsFragment['content'][number];
  isEmphasized?: boolean;
  ItemTracker: ItemTrackerComponent;
}

export const ArticleGroupBlock: React.FC<ArticleGroupBlockProps> = ({ block, isEmphasized = false, ItemTracker }) => {
  if (block?.__typename !== 'ArticleGroupBlock' || !block.layout || !block.imagePlacement) {
    return null;
  }

  return (
    <StyledBlockLayout $layout={block.layout} $imagePlacement={block.imagePlacement} $isEmphasized={isEmphasized}>
      {block.content.map((content) => {
        if (!content || !content.slug || content.__typename !== 'ArticleGroupArticle') {
          return null;
        }

        // if an emphasized block only has one item with a left-aligned image we want to make it a bit larger.
        const size =
          block.imagePlacement === ImagePlacement.Left && block.content.length === 1 && isEmphasized
            ? 'large'
            : 'regular';

        return (
          <ItemTracker style={{ flex: 1 }} item={content} key={content.id}>
            <ArticleGroupBlockArticleLink
              imagePlacement={block.imagePlacement}
              article={content}
              isEmphasized={isEmphasized}
              size={size}
            />
          </ItemTracker>
        );
      })}
    </StyledBlockLayout>
  );
};

const StyledBlockLayout = styled.div<{
  $layout: Layout;
  $imagePlacement: ImagePlacement;
  $isEmphasized: boolean;
}>`
  display: flex;
  flex: 1;
  flex-direction: ${({ $layout }) => ($layout === Layout.Row ? 'row' : 'column')};
  width: 100%;
  ${({ $isEmphasized }) => !$isEmphasized && `background-color: ${colors.white[100]};`}
  ${({ theme }) => theme.mq.mobile} {
    flex-direction: ${({ $layout, $imagePlacement }) =>
      getMobileLayout({ layout: $layout, imagePlacement: $imagePlacement }) === Layout.Row ? 'row' : 'column'};
  }

  /* Apply a divider line between articles only when in column layout */
  ${({ $layout }) =>
    $layout === Layout.Column &&
    `
    & > *:not(:last-child) {
      border-bottom: 1px solid ${colors.gray.dark};
    }
  `}

  /* Apply a divider between articles when in row layout */
  ${({ $layout }) => $layout === Layout.Row && `gap: 4px;`}
`;
