import styled from 'styled-components';
import { createItemTrackerComponent } from 'tracking/ItemTracker';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { PaginatedNewsFeedFieldsFragment } from '@news/gql';
import { GroupId } from '@news/tracking';

import { MaxWidthLayout } from 'components/common';
import { ArticleGroup } from 'views/feed/components/article-group/ArticleGroup';

export default function EmphasizedArticleGroup({
  group,
}: {
  group: PaginatedNewsFeedFieldsFragment['emphasizedArticleGroup'];
}) {
  if (group?.content.length === 0) return null;

  return (
    <EmphasizedWrapper>
      {group?.content.map((item, index) => {
        const ItemTrackerComponent = createItemTrackerComponent({
          index,
          groupId: GroupId.EmphasizedArticleGroup,
          kilkayaPositionId: 'article',
        });

        return <ArticleGroup key={item.id} articleGroup={item} index={0} ItemTracker={ItemTrackerComponent} />;
      })}
    </EmphasizedWrapper>
  );
}

const EmphasizedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${sizeUnits[8]};
  gap: ${sizeUnits[16]};
  ${MaxWidthLayout()};
`;
